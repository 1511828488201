<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="userId == null ? 'AddUser' : 'EditUser'">
    </breadcrumb-base>
    <transition name="fade">
      <div class="content-center">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="$t(userId == null ? 'AddNewStaff' : 'EditStaff')"
                   :subtitle="$t(userId == null ? 'AddNewStaffTag' : '')">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full">

                <div class="vx-col w-full mt-6" v-if="userId == null">
                  <vs-input
                    v-validate="'required'"
                    validate-on="change"
                    :name="$t('FirstName')"
                    class="w-full" :label-placeholder="$t('FirstName')"
                    v-model="firstName"/>
                  <span class="text-danger text-sm">{{ errors.first($t('FirstName')) }}</span>
                </div>

                <div class="vx-col w-full mt-6" v-if="userId == null">
                  <vs-input
                    v-validate="'required'"
                    validate-on="change"
                    :name="$t('LastName')"
                    class="w-full" :label-placeholder="$t('LastName')"
                    v-model="lastName"/>
                  <span class="text-danger text-sm">{{ errors.first($t('LastName')) }}</span>
                </div>

                <div class="vx-col w-full mt-6">
                  <vs-input
                    :disabled="userId != null"
                    v-validate="'required|min:3'"
                    validate-on="change"
                    :name="$t('UserName')"
                    class="w-full" :label-placeholder="$t('UserName') + '*'"
                    @change="checkUserDebounceTime"
                    v-model="username"/>
                  <span class="text-danger text-sm">{{ errors.first($t('UserName')) }}</span>
                  <span class="text-danger text-sm" v-if="!usernameAvailable">{{ $t('UsernameNotAvailable') }}</span>
                </div>

                <div class="vx-col w-full mt-6">
                  <vs-input
                    :disabled="email != null"
                    v-validate="'required'"
                    validate-on="change"
                    :name="$t('EmailId')"
                    class="w-full" :label-placeholder="$t('EmailId') + '*'"
                    v-model="emailId"/>
                  <span class="text-danger text-sm">{{ errors.first($t('EmailId')) }}</span>
                </div>

                <div class="vx-col w-full mt-6" v-if="userId == null">
                  <vs-input
                    v-validate="'required|min:6'"
                    validate-on="change"
                    :name="$t('Password') + '*'"
                    type="password"
                    @input="passCheck"
                    class="w-full" :label-placeholder="$t('Password')"
                    v-model="password"/>
                  <span class="text-danger text-sm">{{ errors.first($t('Password')) }}</span>
                </div>

                <div class="vx-col px-3 w-full" v-if="userId == null">
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.caps ? 'text-success' : 'text-gray']">{{ $t('UppercaseLetter') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.small ? 'text-success' : 'text-gray']">{{ $t('LowercaseLetter') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.numbers ? 'text-success' : 'text-gray']">{{ $t('Number') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.special ? 'text-success' : 'text-gray']">{{ $t('OneSymbol') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.length ? 'text-success' : 'text-gray']">{{ $t('EightCharacters') }}</p>
                </div>

                <div class="vx-col w-full mt-6" v-if="userId == null">
                  <vs-input
                    v-validate="'required'"
                    validate-on="change"
                    :name="$t('ConfirmPassword') + '*'"
                    type="password"
                    class="w-full" :label-placeholder="$t('ConfirmPassword')"
                    v-model="confirmPassword"/>
                  <span class="text-danger text-sm">{{ errors.first($t('ConfirmPassword')) }}</span>
                </div>

                <vs-checkbox class="vx-col mt-6" v-model="userActive">Active</vs-checkbox>
                <vs-checkbox class="vx-col mt-6" v-model="isManager">Is Manager</vs-checkbox>

                <vs-select
                  v-if="hasCreditAndBalance"
                  placeholder="Multiple"
                  multiple
                  class="vx-col mt-6 w-full"
                  :label="$t('SelectTopUpPaymentMode') "
                  v-model="selectedPayments"
                >
                  <vs-select-item :key="index" :value="item" :text="getCreditModeText(item) | uppercase" v-for="(item,index) in paymentData" />
                </vs-select>

                <div class="mt-6">
                  <label for="mobile" class="inline-block mb-1 font-medium">{{ $t('MobileNumber') }}</label>
                  <input
                    placeholder="+491234567890"
                    required=""
                    type="text"
                    v-model="mobile"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="mobile"
                    name="mobile"
                  />
                </div>
                <div class="mt-6" v-if="userId != null">
                  <vs-button @click="showChangePassword = true" color="danger" type="filled" >{{ $t('ChangePassword') }}
                  </vs-button>
                </div>
              </div>

              <div class="vx-col md:w-1/2 w-full">
                <div class="vx-col mb-2 mt-6 px-12">
                  <div class="vx-col">
                    <h4>{{$t('Privileges')}} :</h4>
                    <div class="mt-6"></div>
                  </div>
                  <div class="">
                    <vs-checkbox class="vx-col mt-3"
                                 v-if="activeUserInfo.account_role === 0"
                                 v-model="hasProducts" >{{ $t('Products') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasSuppliers">{{ $t('Suppliers') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasInventory">{{ $t('Inventory') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasGiftCard">{{ $t('GiftCards') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasCreditAndBalance">{{ $t('TopUp') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasAutoCredit">{{ $t('AutoCredit') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasLoginRequests">{{ $t('LoginRequests') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasReclamation">{{ $t('Reclamation') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3"
                                 v-if="activeUserInfo.account_role === 0"
                                 v-model="hasDynamicProducts">{{ $t('DynamicProducts') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasDiscountSlab">{{ $t('DiscountSlab') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasCustomers">{{ $t('CustomerFullPermission') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasCustomersRead">{{ $t('CustomerReadOnly') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasReports">{{ $t('Reports') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasInvoices">{{ $t('Invoices') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasDevices">{{ $t('Devices') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasAssignProducts">{{ $t('AssignProducts') }}</vs-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row mt-6">
              <vs-button class="ml-4" :disabled="userId == null ? !validateForm : false"
                         @click="userId == null ? addUserAndForward() : updateUserAndForward()">{{userId == null ?  $t('SaveUser') : $t('UpdateUser') }}
              </vs-button>
            </div>
            <div class="m-4"></div>

          </vx-card>
        </div>
      </div>
    </transition>


    <vx-modal v-if="showChangePassword" @close="showChangePassword = false" component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body" >
        <div class="p-6 flex flex-centered" >
          <vx-col>
            <h3><strong>Change Password</strong></h3>
            <div  class="w-full py-4 vs-row">
              <vs-input
                autofocus class="w-full py-"
                size="large" type="password" v-validate="'required'"
                validate-on="change"
                @input="changePassCheck"
                :label-placeholder="$t('Password')" v-model="newPassword"/>

              <div class="vs-col">
                <p class="text-sm pass-check-item" :class="[passStrengthValue.caps ? 'text-success' : 'text-gray']">{{ $t('UppercaseLetter') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.small ? 'text-success' : 'text-gray']">{{ $t('LowercaseLetter') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.numbers ? 'text-success' : 'text-gray']">{{ $t('Number') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.special ? 'text-success' : 'text-gray']">{{ $t('OneSymbol') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.length ? 'text-success' : 'text-gray']">{{ $t('EightCharacters') }}</p>
              </div>

            </div>
            <div class="vs-row">
              <vs-input class="w-full py-4" size="large" type="password" v-validate="'required'"
                        validate-on="change"
                        :label-placeholder="$t('ConfirmPassword')"
                        v-model="newConfirmPassword"/>

            </div>
            <div class="vs-row flex justify-center mt-3" >
              <vs-button class="ml-2 mt-3" color="primary" @click="changePassword()">{{ $t('ChangePassword') }}</vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showChangePassword = false">{{ $t('Close') }}</vs-button>
            </div>
          </vx-col>

        </div>
      </template>
      <template slot="footer"></template>
    </vx-modal>
  </div>
</template>

<script>
import VxModal from '@/layouts/components/custom/VxModal.vue'

export default {
  name: 'AddUser',
  components: {
    VxModal,
  },
  computed: {
    paymentData() {
      return this.activeUserInfo.credit_transaction_type_modes
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    validateForm() {
      return !this.errors.any() && this.firstName !== '' && this.lastName !== ''
          && this.username !== '' && this.password !== '' && this.confirmPassword !== ''
          && this.usernameAvailable
    },
  },
  data() {
    return {
      newPassword: '',
      newConfirmPassword: '',
      showChangePassword: false,
      selectedPayments: [],
      usernameAvailable: true,
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      mobile: '',
      emailId: '',
      confirmPassword: '',
      hasCustomers: false,
      hasCustomersRead: false,
      hasAutoCredit: false,
      hasSuppliers: false,
      hasProducts: false,
      hasReports: false,
      hasInvoices: false,
      hasDevices: false,
      hasDynamicProducts: false,
      hasCreditAndBalance: false,
      hasLoginRequests: false,
      hasReclamation: false,
      hasInventory: false,
      hasGiftCard: false,
      hasAssignProducts: false,
      hasDiscountSlab: false,
      internalPermissionId: null,
      userActive: true,
      isManager: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Users', i18n: 'Users', url: '/users/view' },
        { title: 'Add User', i18n: 'AddUser', active: true },
      ],
      passStrengthValue: {
        'caps': false,
        'length': false,
        'special': false,
        'numbers': false,
        'small': false,
      },
    }
  },
  props: {
    userId: {
      type: Number || String,
      default: null,
    },
  },
  methods: {

    getCreditModeText(mode) {
      if(mode == 'credit_note') return 'Gutschrift'
      if(mode == 'credit') return 'Kredit'
      return mode
    },
    changePassword() {
      if (!(this.passStrengthValue.caps && this.passStrengthValue.length
        && this.passStrengthValue.special && this.passStrengthValue.numbers
        && this.passStrengthValue.small)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password is not valid',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return null
      }
      if (this.newPassword !== this.newConfirmPassword) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password not matched',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return null
      }
      this.$vs.loading()
      return this.$store.dispatch('customer/changeCustomerPassword',
        { customerId: this.userId, password: this.newPassword, username: this.username })
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type === 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: 'Password updated',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
            this.newConfirmPassword = ''
            this.newPassword = ''
            this.showChangePassword = false
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    changePassCheck() {
      let caps = false
      let numbers = false
      let small = false
      let special = false
      for (let index = 0; index < this.newPassword.length; index += 1) {
        const char = this.newPassword.charCodeAt(index)
        if (char >= 65 && char <= 90) {
          caps = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if (char >= 97 && char <= 122) {
          small = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if ([33, 35, 36, 37, 38, 64, 42, 63, "33", "35", "36", "37", "38", "64", "42", "63"].includes(char)) {
          special = true
        }
      }
      this.passStrengthValue.caps = caps
      this.passStrengthValue.numbers = numbers
      this.passStrengthValue.small = small
      this.passStrengthValue.special = special
      if (this.newPassword.length < 8) {
        this.passStrengthValue.length = false
      } else {
        this.passStrengthValue.length = true
      }
      const obj = { ...this.passStrengthValue }
      this.passStrengthValue = {}
      this.passStrengthValue = { ...obj }
    },
    passCheck() {
      let caps = false
      let numbers = false
      let small = false
      let special = false
      for (let index = 0; index < this.password.length; index += 1) {
        const char = this.password.charCodeAt(index)
        if (char >= 65 && char <= 90) {
          caps = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if (char >= 97 && char <= 122) {
          small = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if ([33, 35, 36, 37, 38, 64, 42, 63, "33", "35", "36", "37", "38", "64", "42", "63"].includes(char)) {
          special = true
        }
      }
      this.passStrengthValue.caps = caps
      this.passStrengthValue.numbers = numbers
      this.passStrengthValue.small = small
      this.passStrengthValue.special = special
      if (this.password.length < 8) {
        this.passStrengthValue.length = false
      } else {
        this.passStrengthValue.length = true
      }
      const obj = { ...this.passStrengthValue }
      this.passStrengthValue = {}
      this.passStrengthValue = { ...obj }
      console.log(this.passStrengthValue)
    },
    checkUserDebounceTime() {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.checkUsername()
      }, 500)
    },
    checkUsername() {
      this.usernameAvailable = true
      this.$store.dispatch('user/checkCustomerUsername', this.username)
        .then((data) => {
          console.log(data)
          if (data.message_type === 1) {
            this.usernameAvailable = true
          } else {
            this.usernameAvailable = false
          }
        })
        .catch((err) => {
          if (err.response.data.message_type === 0) {
            this.usernameAvailable = false
          }
        })
    },
    updateUserAndForward() {
      const payload = {
        has_customers: this.hasCustomers === true ? 1 : 0,
        has_customers_read: this.hasCustomersRead === true ? 1 : 0,
        has_auto_credit: this.hasAutoCredit === true ? 1 : 0,
        has_supplier: this.hasSuppliers === true ? 1 : 0,
        has_gift_card: this.hasGiftCard === true ? 1 : 0,
        has_product: this.hasProducts === true ? 1 : 0,
        has_reports: this.hasReports === true ? 1 : 0,
        has_invoice: this.hasInvoices === true ? 1 : 0,
        has_dynamic_products: this.hasDynamicProducts === true ? 1 : 0,
        has_login_requests: this.hasLoginRequests === true ? 1 : 0,
        has_reclamation: this.hasReclamation === true ? 1 : 0,
        has_device: this.hasDevices === true ? 1 : 0,
        has_inventory: this.hasInventory === true ? 1 : 0,
        has_assign_product: this.hasAssignProducts === true ? 1 : 0,
        has_discount_slab: this.hasDiscountSlab === true ? 1 : 0,
        has_credit_and_balance: this.hasCreditAndBalance === true ? 1 : 0,
        is_active: this.userActive === true ? 1 : 0,
        is_manager: this.isManager === true ? 1 : 0,
        internal_permission_id: this.internalPermissionId,
        mobile: this.mobile,
        email: this.emailId,
        credit_transaction_modes: this.selectedPayments ? this.selectedPayments.join(',') : '',
      }
      payload.user_id = this.userId
      this.$vs.loading()

      this.$store.dispatch('user/updateUser', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.$router.push({ name: 'users-view' })
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    addUserAndForward() {
      let passValid = true
      Object.keys(this.passStrengthValue).forEach((k) => {
        if (this.passStrengthValue[k] === false) {
          passValid = false
        }
      })

      if (!passValid) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password not valid',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.password.length < 6) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password should be minimum of 6 digits',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.password !== this.confirmPassword) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password not matched',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }

      const payload = {
        first_name: this.firstName,
        last_name: this.lastName,
        username: this.username,
        password: this.password,
        mobile: this.mobile,
        email: this.emailId,
        has_product: this.hasProducts === true ? 1 : 0,
        has_supplier: this.hasSuppliers === true ? 1 : 0,
        has_inventory: this.hasInventory === true ? 1 : 0,
        has_gift_card: this.hasGiftCard === true ? 1 : 0,
        has_discount_slab: this.hasDiscountSlab === true ? 1 : 0,
        has_dynamic_products: this.hasDynamicProducts === true ? 1 : 0,
        has_customers: this.hasCustomers === true ? 1 : 0,
        has_customers_read: this.hasCustomersRead === true ? 1 : 0,
        has_assign_product: this.hasAssignProducts === true ? 1 : 0,
        has_credit_and_balance: this.hasCreditAndBalance === true ? 1 : 0,
        has_auto_credit: this.hasAutoCredit === true ? 1 : 0,
        has_reports: this.hasReports === true ? 1 : 0,
        has_device: this.hasDevices === true ? 1 : 0,
        has_login_requests: this.hasLoginRequests === true ? 1 : 0,
        has_reclamation: this.hasReclamation === true ? 1 : 0,
        has_invoice: this.hasInvoices === true ? 1 : 0,
        is_active: this.userActive === true ? 1 : 0,
        is_manager: this.isManager === true ? 1 : 0,

        credit_transaction_modes: this.selectedPayments.join(','),
      }
      this.$vs.loading()

      this.$store.dispatch('user/addNewUser', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.$router.push({ name: 'users-view' })
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
  },
  mounted() {
    if (this.userId !== null) {
      this.$vs.loading()
      return this.$store.dispatch('user/fetchSingleUser', this.userId)
        .then((data) => {
          this.username = data.user_name
          this.hasCustomers = data.has_customers === 1
          this.hasCustomersRead = data.has_customers_read === 1
          this.hasSuppliers = data.has_supplier === 1
          this.hasGiftCard = data.has_gift_card === 1
          this.hasDynamicProducts = data.has_dynamic_products === 1
          this.hasLoginRequests = data.has_login_requests === 1
          this.hasProducts = data.has_product === 1
          this.hasReports = data.has_reports === 1
          this.hasInvoices = data.has_invoice === 1
          this.hasDevices = data.has_device === 1
          this.hasInventory = data.has_inventory === 1
          this.hasAssignProducts = data.has_assign_product === 1
          this.hasReclamation = data.has_reclamation === 1
          this.hasDiscountSlab = data.has_discount_slab === 1
          this.hasCreditAndBalance = data.has_credit_and_balance === 1
          this.hasAutoCredit = data.has_auto_credit === 1
          this.userActive = data.is_active
          this.mobile = data.mobile
          this.emailId = data.email
          this.isManager = data.is_manager === 1

          this.internalPermissionId = data.internal_permission_id

          this.selectedPayments = data.credit_transaction_modes || []


          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
    return null
  },
}
</script>

<style scoped>
  .c-center {
    margin: auto;
  }

  .upload-btn-wrapper input[type=file] {
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  .p-image {
    width: 90%;
    object-fit: cover;
    max-height: 200px;
    margin: 1rem;
  }

  .p-image-denomination {
    width: 50%;
  }
</style>
